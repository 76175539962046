.total-taxes--grid {
  display: grid;
  grid-template-columns: minmax(180px, 2fr)  minmax(500px, 10fr);
  grid-gap: 1rem;
  grid-auto-flow: row;

}
@media screen and (max-width: 750px) {
  .total-taxes--grid {
    grid-template-columns: 1fr;

  }
}