.rectangle-down {
  width: 0; 
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 12px solid red;
  position: absolute;
  left: -17px;
  top: 4px;
 }
 .rectangle-down--container {
  position: relative;
 }