.vertical-line {
  border-left: 1px dotted grey;
  height: 100%;
  position: absolute;
 }
 .vertical-line-num{
  position: absolute;
  bottom: 0;
  font-size: 12px;
  color: grey
 }
