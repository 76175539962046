.chart-rectangle-down {
  width: 0; 
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  position: absolute;
  left: 10px;
  top: 10px;

 }
 .chart-rectangle-down--container {
  position: relative;
  z-index: 4001;
 }