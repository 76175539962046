.rectangle-up {
    width: 0; 
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 12px solid green;
    position: absolute;
    left: -17px;
    top: 4px;
 }
 .rectangle-up--container {
  position: relative;
 }